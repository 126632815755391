import "./index.scss";
import { useContext, useEffect, useState } from "react";
import Context from "../../Context";
import Logo from "../../Assets/images/logo-w.png";
import { Link } from "react-router-dom";

import Facebook from "../../Assets/images/icons/facebook.svg";
import LinkedIn from "../../Assets/images/icons/linkedin.svg";
import Instagram from "../../Assets/images/icons/instagram.svg";
import YouTube from "../../Assets/images/icons/youtube.svg";

const Footer = () => {
    const { resumo } = useContext(Context);
    const [data, setData] = useState({});

    useEffect(() => {
        let categorias = [];
        resumo.categorias.some(categoria => {
            categorias.push(categoria);
            if (categorias.length >= 4) {
                return true;
            } else {
                return false;
            }
        });

        let linhas = [];
        resumo.linhas.some(linha => {
            linhas.push(linha);
            if (linhas.length >= 4) {
                return true;
            } else {
                return false;
            }
        });

        setData({ categorias: categorias, linhas: linhas })

    }, [resumo]);

    return (
        <div id="footer">
            <div className="container">
                <div className="main">
                    <div>
                        <img src={Logo} alt="Terramada" />
                        <div className="socials">
                            {resumo.configuracoes.facebook.length > 5 && <a href={resumo.configuracoes.facebook} target="_blank" title="Facebook" rel="noopener noreferrer"><img src={Facebook} alt="Facebook" /></a>}
                            {resumo.configuracoes.linkedin.length > 5 && <a href={resumo.configuracoes.linkedin} target="_blank" title="LinkedIn" rel="noopener noreferrer"><img src={LinkedIn} alt="LinkedIn" /></a>}
                            {resumo.configuracoes.instagram.length > 5 && <a href={resumo.configuracoes.instagram} target="_blank" title="Instagram" rel="noopener noreferrer"><img src={Instagram} alt="Instagram" /></a>}
                            {resumo.configuracoes.youtube.length > 5 && <a href={resumo.configuracoes.youtube} target="_blank" title="YouTube" rel="noopener noreferrer"><img src={YouTube} alt="YouTube" /></a>}
                        </div>
                    </div>
                    <div>
                        <p>Nosso site</p>
                        <div>
                            <Link to="/" title="Home">Home</Link>
                            <Link to="/empresa" title="A Terramada">A Terramada</Link>
                            <Link to="/produtos" title="Produtos">Produtos</Link>
                            <Link to="/contato" title="Contato">Contato</Link>
                        </div>
                    </div>
                    <div>
                        <p>Institucionais</p>
                        <div>
                            <Link to="/onde-comprar" title="Onde Comprar">Onde Comprar</Link>
                            <Link to="/nossos-representantes" title="Representantes">Representantes</Link>
                            <Link to="/politica-de-privacidade" title="Política de Privacidade">Política de Privacidade</Link>
                            <Link to="/termos-de-uso" title="Termos de uso">Termos de uso</Link>
                        </div>
                    </div>
                    <div>
                        <p>Categorias</p>
                        <div>
                            {data.categorias && data.categorias.map(categoria => <Link key={`lista-categorias-footer-${categoria.id}`} to={`/produtos/categoria/${categoria.slug}`} title={categoria.nome}>{categoria.nome}</Link>)}
                        </div>
                    </div>
                    <div>
                        <p>Linhas</p>
                        <div>
                            {data.linhas && data.linhas.map(linha => <Link key={`lista-linha-footer-${linha.id}`} to={`/produtos/linha/${linha.slug}`} title={linha.nome}>{linha.nome}</Link>)}
                        </div>
                    </div>
                </div>
                <div className="creditos">
                    <p>Terramada Porcelanas - CNPJ: {resumo.configuracoes.cnpj} - {resumo.configuracoes.endereco} - {resumo.configuracoes.bairro}, {resumo.configuracoes.cidade} - {resumo.configuracoes.estado}, {resumo.configuracoes.cep}</p>
                    <p>Desenvolvido por <a href="https://fw2propaganda.com.br/" target="_blank" rel="noopener noreferrer" title="Desenvolvido por Gustavo Barbosa">FW2 Propaganda</a></p>
                </div>
            </div>
        </div>
    )
}

export default Footer;
import { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/images/logo.png";
import Menu from "../../Assets/images/icons/menu.svg";
import Close from "../../Assets/images/icons/close.svg";
import "./index.scss";

const Header = () => {
    const [menu, setMenu] = useState(false);
    
    return (
        <div id="header">
            <div className="container">
                <Link to="/" title="Home"><img src={Logo} alt="Terramada" /></Link>
                <button type="button" onClick={() => setMenu(!menu)}><img src={menu ? Close : Menu} alt="Menu" /></button>
                <div className={`menu ${menu ? "active" : ""}`}>
                    <Link to="/" title="Home">Home</Link>
                    <Link to="/empresa" title="A Terramada">A Terramada</Link>
                    <Link to="/produtos" title="Produtos">Produtos</Link>
                    <Link to="/onde-comprar" title="Onde comprar">Onde comprar</Link>
                    <Link to="/contato" title="Contato">Contato</Link>
                    <Link to="/nossos-representantes" title="Nossos representantes">Nossos representantes</Link>
                </div>
            </div>
        </div>
    )
}

export default Header;
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { Suspense, lazy, useState, useEffect, useContext } from 'react';
import Context from './Context';

import ScrollToTop from "./Components/ScrollToTop";

import Header from "./Components/Header";
import Footer from "./Components/Footer";
import axios from "axios";

import { numbersOnly } from "./Utils";
import Whatsapp from "./Assets/images/icons/whatsapp.svg";

const Cookies = lazy(() => import('./Components/Cookies'));

const Home = lazy(() => import('./Pages/Home'));
const Empresa = lazy(() => import('./Pages/Empresa'));
const Comprar = lazy(() => import('./Pages/Comprar'));
const Produtos = lazy(() => import('./Pages/Produtos'));
const Produto = lazy(() => import('./Pages/Produto'));
const Representante = lazy(() => import('./Pages/Representante'));
const Contato = lazy(() => import('./Pages/Contato'));
const Texto = lazy(() => import('./Pages/Texto'));
const NotFound = lazy(() => import('./Pages/NotFound'));


function App() {
  localStorage.setItem("apiUrl", "/sistema/api/");
  const [resumo, setResumo] = useState({});
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(false);
  const value = { resumo, setResumo, modal, setModal, activePage, setActivePage };

  useEffect(() => {
    axios.get(localStorage.getItem("apiUrl") + "configuracoes").then(response => {
      setResumo(response.data.success);
    });
  }, []);

  return (
    <HelmetProvider>
      <Context.Provider value={value}>
        <div id="modal" className={modal ? "active" : ""}>
          {modal && <div className="content">
            <h3>{modal.titulo}</h3>
            <p>{modal.texto}</p>
            <button type="button" onClick={() => { if (modal.onExit) { modal.onExit() } setModal(false) }}>{modal.botao}</button>
          </div>}
        </div>
        <Router>
          <Switch>
            <Route path="*">
              <Application />
            </Route>
          </Switch>
        </Router>
      </Context.Provider>
    </HelmetProvider>
  );
}

const Application = () => {
  const { resumo } = useContext(Context);
  let location = useLocation();
  return (
    <div className="App">
      <ScrollToTop />
      {resumo.configuracoes &&
        <>
          <Header />
          <Suspense fallback={<div className="suspense"></div>}>
            <TransitionGroup>
              <CSSTransition key={location.key} timeout={300} classNames="fade" mountOnEnter={true} unmountOnExit={true}>
                <Switch location={location}>
                  <Route path="/" exact>
                    <Home />
                  </Route>
                  <Route path="/empresa" exact>
                    <Empresa />
                  </Route>
                  <Route path="/produtos" exact>
                    <Produtos />
                  </Route>
                  <Route path="/produtos/categoria/:categoria" exact>
                    <Produtos />
                  </Route>
                  <Route path="/produtos/linha/:linha" exact>
                    <Produtos />
                  </Route>
                  <Route path="/produto/:slug" exact>
                    <Produto />
                  </Route>
                  <Route path="/onde-comprar" exact>
                    <Comprar />
                  </Route>
                  <Route path="/nossos-representantes" exact>
                    <Representante />
                  </Route>
                  <Route path="/contato" exact>
                    <Contato />
                  </Route>
                  {resumo.textos &&
                    <Route path="/termos-de-uso" exact>
                      <Texto titulo={"Termos de uso"} texto={resumo.textos.termos_de_uso} />
                    </Route>
                  }
                  {resumo.textos &&
                    <Route path="/politica-de-privacidade" exact>
                      <Texto titulo={"Política de privacidade"} texto={resumo.textos.politica_de_privacidade} />
                    </Route>
                  }
                  <Route component={NotFound} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
            <Cookies />
          </Suspense>
          <Footer />
        </>
      }
      {
        resumo.configuracoes && <div itemScope itemType="https://schema.org/LocalBusiness" className="schema">
          <span itemProp="name">{resumo.configuracoes.app_title}</span>
          {resumo.seo.empresa && <span itemProp="image">{resumo.seo.empresa.imagem}</span>}
          <div itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
            <span itemProp="streetAddress">{resumo.configuracoes.endereco}</span>
            <span itemProp="addressLocality">{resumo.configuracoes.cidade}</span>,
            <span itemProp="addressRegion">{resumo.configuracoes.estado}</span> <span itemProp="postalCode">{resumo.configuracoes.cep}</span>
          </div>
          <span itemProp="telephone">{resumo.configuracoes.telefone}</span>
          <a itemProp="url" href={resumo.configuracoes.url}>{resumo.configuracoes.url}</a>
          <meta itemProp="openingHours" content={resumo.configuracoes.horario} />
        </div>
      }
      {resumo.configuracoes && resumo.configuracoes.whatsapp && <a href={`https://wa.me/55${numbersOnly(resumo.configuracoes.whatsapp)}`} target="_blank" rel="noreferrer noopener" className="floating-whatsapp" title="Fale conosco">
        <img src={Whatsapp} width="48px" height="48px" alt="whatsapp" />
      </a>}
    </div >
  )
}

export default App;
